/* stylelint-disable selector-class-pattern */

@use './typography';
@use './colors.module.scss';
@use './breakpoints.module.scss';

* {
  margin: 0;
  padding: 0;
  font-family: inherit;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  background: none;
  border: none;
  cursor: pointer;
}

body {
  line-height: 1;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

input {
  appearance: none;
  border-radius: 0;
}

html {
  font-size: 62.5%; // 1rem = 10px
}

body {
  @media (min-width: breakpoints.$breakpoint-small) {
    .display {
      font-size: 12rem;
      line-height: 10rem;
    }

    .small-display {
      font-size: 3.4rem;
      line-height: 4rem;
    }

    .h1 {
      font-size: 7.6rem;
      line-height: 8.2rem;
    }

    .h2 {
      font-size: 3.2rem;
      line-height: 4rem;
    }

    .h3 {
      font-size: 5.4rem;
      line-height: 6rem;
    }

    .h4 {
      font-size: 3.2rem;
      line-height: 3.6rem;
    }

    .h5 {
      font-size: 2.2rem;
      line-height: 2.8rem;
    }

    .h6 {
      font-size: 2rem;
      line-height: 2.4rem;
    }

    .body-1 {
      font-size: 2.6rem;
      line-height: 3.4rem;
    }

    .body-2 {
      font-size: 2rem;
      line-height: 2.4rem;
    }

    .body-3,
    .label-1 {
      font-size: 1.6rem;
      line-height: 2rem;
    }

    .body-4 {
      font-size: 1.4rem;
      line-height: 1.6rem;
    }

    .label-2 {
      font-size: 2rem;
      line-height: 1.8rem;
    }

    .label-3 {
      font-size: 1.6rem;
      line-height: 2rem;
    }
  }
}
