@use '../../theme.module.scss';

$padding-s: 20px;

.container {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr 90% 1fr;
  min-width: theme.$breakpoint-xs;

  .contentWrapper {
    grid-column: 2 / 2;
  }

  &.snow {
    background-color: theme.$snow;
  }

  &.sand {
    background-color: theme.$sand;
  }

  &.first {
    padding-top: 20px;
  }

  &.last {
    padding-bottom: 20px;
  }

  @media (min-width: theme.$breakpoint-medium) {
    // grid-template-columns: 1fr calc(theme.$breakpoint-medium - ($padding-s * 2)) 1fr;
    grid-template-columns: 1fr 500px 1fr;
    min-width: theme.$breakpoint-medium;
  }
}
