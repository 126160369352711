.fields {
  margin-left: 0px;
}

.field {
  display: flex;
  flex-direction: row;
  margin: 10px 0px;
  align-items: baseline;
}

.label {
  margin-right: 10px;
}
