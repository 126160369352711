@use '../../theme.module.scss';
@use '../../styles/mixins.scss';

$border-right-width: 10px;

.label {
  display: flex;
  flex-direction: column;
  width: 335px;
  color: theme.$midnight;
  font-weight: 500;
  font-size: 21px;

  .textWrapper {
    min-height: 28px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .labelText {
      font-size: 14px;
      font-family: theme.$font-primary;
      min-width: 50%;

      &.hideLabelText {
        visibility: hidden;
      }
    }
  }

  &.error {
    input,
    textarea {
      border-right: $border-right-width solid theme.$lava;

      &:focus {
        outline: none;
        border-right: $border-right-width solid theme.$lava;
      }
    }
  }

  input,
  textarea {
    @include mixins.disabled;

    background: theme.$snow;
    border: none;
    font-family: theme.$font-primary;
    width: 100%;
    box-shadow: theme.$box-shadow;
    height: 60px;
    font-size: 18px;
    font-weight: 500;
    color: theme.$midnight;
    padding-left: 20px;
    margin-top: 5px;

    &::placeholder {
      color: theme.$midnight-60;
      font-size: 16px;
    }

    &:focus {
      outline: none;
      border-right: $border-right-width solid theme.$neon-grass;

      &::placeholder {
        visibility: hidden;
      }
    }
  }

  textarea {
    min-height: 60px;
    padding: 20px;

    &:focus {
      padding-right: 20px - $border-right-width;
    }
  }
}
