@use '../theme/colors.module.scss';

$overlay-size: 87%;

.container {
  height: 60px;
  width: 60px;
}

.positioningWrapper {
  height: 100%;
  width: 100%;
  position: relative;
}

.container,
.positioningWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay {
  height: $overlay-size;
  width: $overlay-size;
  background-color: colors.$neon-grass;
  border-radius: 50%;
  position: absolute;
  z-index: 0;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  z-index: 1;
}

.icon > svg {
  display: block;
  height: 100%;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
}
