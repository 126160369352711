@use '../../sharedComponents/theme.module.scss';

.vrtWrapperOuter {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
}

.vrtWrapperInner {
  /* stylelint-disable */
  @extend .vrtWrapperOuter;
  > p:first-child {
    margin-bottom: 3rem;
  }
}

.header {
  padding-bottom: 2rem;
  margin-right: auto;
  > svg {
    display: flex;
    height: 1.6rem;
  }
}

.displayName {
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}
