@use './theme-definitions';

$themes: ();
$themeValues: ();
$defaultTheme: 'light';

// Theme Functions
@function themeExists($key, $theme) {
  @if map-has-key($themeValues, $key) {
    @return map-has-key(map-get($themeValues, $key), $theme);
  }

  @return null;
}

@function getThemeValue($key, $theme) {
  @return map-get(map-get($themeValues, $key), $theme);
}

// Theme Mixins
@mixin getThemedProperty(
  $property,
  $key,
  $inlineExtra: null,
  $postExtra: null
) {
  @each $theme in $themes {
    @if themeExists($key, $theme) {
      $value: getthemevalue($key, $theme);
      :global(.#{$theme}) & {
        #{$property}: #{$inlineExtra} #{$value} #{$postExtra};
      }
    } @else {
      @if themeExists($key, light) {
        :global(.#{$defaultTheme}) & {
          #{$property}: #{$inlineExtra} #{$value} #{$postExtra};
        }
      } @else {
        @error "Property #{$key} doesn't exist in #{$theme} or in default theme #{$defaultTheme}";
      }
    }
  }
}

@mixin generateThemeMappings($themeName, $newThemeMap) {
  $themes: append($themes, $themeName, $separator: comma) !global;

  @each $key, $value in $newThemeMap {
    @if not map-has-key($themeValues, $key) {
      $themeValues: map-merge(
        $themeValues,
        (
          $key: (
            $themeName: $value,
          ),
        )
      ) !global;
    } @else {
      $existingKeyMap: map-get($themeValues, $key);

      @if not map-get($existingKeyMap, $themeName) {
        $newKeyMap: map-merge(
          $existingKeyMap,
          (
            $themeName: $value,
          )
        );
        $themeValues: map-merge(
          $themeValues,
          (
            $key: $newKeyMap,
          )
        ) !global;
      }
    }
  }
}

@include generateThemeMappings(light, theme-definitions.$light-theme);
@include generateThemeMappings(dark, theme-definitions.$dark-theme);
