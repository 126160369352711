@use '../../sharedComponents/theme.module.scss';

.container {
  margin: auto;
}

.accountTypeButtonWrapper {
  width: fit-content;
  margin: 0 auto 20px;

  button {
    margin: 0 10px;
  }
}

.form {
  button {
    margin-top: 30px;
  }
}

.active,
.inactive {
  padding: 5px 20px;
}

.active {
  border-bottom: solid 3px theme.$neon-grass;
}
