* {
  margin: 0;
  padding: 0;
  font-family: inherit;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  // 1rem = 10px
  font-size: 62.5%;
}

body {
  font-size: 1rem;
}

/* Cookie banner overrides */
#CybotCookiebotDialog {
  font-family: inherit;
  border-radius: 0;
}

#CybotCookiebotDialogHeader,
#CybotCookiebotDialogPoweredByText,
.CybotExternalLinkArrow {
  display: none;
}

#CybotCookiebotDialog .CybotCookiebotDialogBodyButton {
  border-radius: 40px !important;
}

#CybotCookiebotDialogBodyContentTitle {
  font-size: 2.8rem;
  font-weight: 400 !important;
  line-height: 1.1 !important;
}

#CybotCookiebotDialogBodyContentText {
  font-weight: 400;
}

#CybotCookiebotDialogBody .CybotCookiebotScrollContainer {
  max-height: 19em !important;
}

#CybotCookiebotDialog
  input:checked
  + .CybotCookiebotDialogBodyLevelButtonSlider:before {
  background-color: #0c4261;
}

#CybotCookiebotDialog .CybotCookiebotDialogDetailBulkConsentCount {
  min-width: 20px;
  color: #0c4261 !important;
  text-align: center;
}

@media screen and (min-width: 1280px) {
  #CybotCookiebotDialog .CybotCookiebotDialogContentWrapper {
    align-items: center !important;
  }
}
