@use '../../sharedComponents/theme.module.scss';

.formContainer {
  display: flex;
  flex-direction: column;
  max-width: theme.$breakpoint-small;
  margin: auto;

  .divider {
    border: 2px solid theme.$neon-grass;
    margin-top: 2rem;

    @media (min-width: theme.$breakpoint-small) {
      border: 2.5px solid theme.$neon-grass;
      margin-top: 6rem;
    }
  }

  input {
    margin-bottom: auto;
  }

  .fieldWrapper {
    display: flex;
    height: 8rem;
    align-items: center;
    justify-content: space-between;

    input {
      width: 90%;
    }

    @media (min-width: theme.$breakpoint-small) {
      height: 10rem;
    }
  }

  .notificationWrapper {
    /* stylelint-disable */
    @extend .fieldWrapper;
    justify-content: center;
    margin-bottom: 1rem;

    @media (min-width: theme.$breakpoint-small) {
      margin-bottom: -2rem;
    }
  }

  .passwordBlockPositionWrapper {
    display: flex;
    margin-top: 5rem;
    justify-content: center;
    @media (min-width: theme.$breakpoint-small) {
      margin-top: 3rem;
    }
  }

  .iconButton,
  .iconButtonX {
    width: 2rem;
    height: 2rem;
    &:disabled {
      cursor: unset;
      pointer-events: none;

      svg {
        fill: theme.$midnight-40;
      }
    }
    @media (min-width: theme.$breakpoint-small) {
      width: 2.5rem;
      height: 2.5rem;
    }
  }

  .iconButtonX {
    margin-right: 20px;
    svg > g > path {
      fill: theme.$status-red;
    }
  }
}
