.header {
  margin: 20px 0px 10px;
}

.contentWrapper {
  margin: 20px 0px;
  display: flex;
  flex-direction: column;
}

.buttonWrapper {
  width: fit-content;
  margin: 20px auto;
}

.willBeSavedText {
  margin-top: 20px;
  margin-bottom: 10px;
}
