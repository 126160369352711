@use '../../sharedComponents/theme.module.scss';

.sectionWrapper {
  padding: 1.5rem 0px;
}
.header {
  margin-bottom: 2rem;
}

@media (min-width: theme.$breakpoint-small) {
  .sectionWrapper {
    padding: 2rem 0rem;
  }
}
