@use '../theme.module.scss';

$border-width: 5px;

.container {
  @include theme.getThemedProperty(color, midnight);

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  min-width: 160px;
  height: 100%;
  font-size: 18px;
  font-weight: 400;

  & > * {
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(100% + $border-width * 2);
  }

  &:hover {
    font-weight: 600;
  }

  &.current {
    font-weight: 600;
    border-bottom: $border-width solid theme.$neon-grass;
  }

  &:active {
    font-weight: 600;
    color: theme.$neon-grass;
  }

  &:focus {
    outline: 1px solid theme.$neon-grass;
  }

  @media screen and (max-width: theme.$breakpoint-large) {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
    height: 50px;
  }

  @media screen and (min-width: theme.$breakpoint-large) {
    margin: 0 10px;

    &.current,
    &:active {
      padding-top: $border-width;
    }
  }
}
