// Breakpoints
$breakpoint-xs: 325px; // small mobile
$breakpoint-small: 480px; // mobile
$breakpoint-medium: 768px; // tablet
$breakpoint-large: 1200px; // desktop

$all-breakpoints: (
  breakpointxsmall: $breakpoint-xs,
  breakpointsmall: $breakpoint-small,
  breakpointmedium: $breakpoint-medium,
  breakpointlarge: $breakpoint-large,
);

@mixin map-to-object($map) {
  @if type-of($map) == map {
    @each $prop, $value in $map {
      @if type-of($value) != map {
        @if inspect($prop) == 'description' {
          /* #{inspect($value)} */
        } @else {
          #{inspect($prop)}: #{inspect($value)};
        }
      } @else {
        @include map-to-props($value);
      }
    }
  }
}

:export {
  @include map-to-object($all-breakpoints);
}
