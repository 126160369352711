.headerText {
  margin: 4rem 0 1.4rem;
}

.coverageHasNotStartedText {
  margin-bottom: 30px;
}

.locationDatesWrapper {
  margin-bottom: 33px;
}

.collapseAnimation {
  max-height: 0rem;
  overflow: hidden;
}

.expandAnimation {
  max-height: 70rem;
  transition: max-height 0.6s ease-in-out;
  overflow: hidden;
  padding-bottom: 20px;
}
