.container {
  margin: 2rem auto;
  :first-child {
    margin-bottom: 2rem;
  }

  > * {
    margin-bottom: 0.5rem;
  }
}
