@use '../theme.module.scss';
@use '../theme/colors.module.scss';

.container {
  margin-left: auto;
  margin-right: 0;
  width: fit-content;
  height: fit-content;

  button {
    width: 17px;
    height: 17px;
    transform: rotate(45deg);

    svg {
      height: 100%;
      width: 100%;
    }
  }

  .midnight {
    svg {
      fill: colors.$midnight;
    }
  }

  .snow {
    svg {
      fill: colors.$snow;
    }
  }
}
