@use '../theme.module.scss';
@use '../styles/mixins.scss';

.container {
  display: flex;
  align-items: center;
  width: max-content;
  padding: 0.5rem;

  .back {
    display: flex;
    height: 1.3rem;
    width: 1.3rem;
    margin-right: 0.5rem;

    > svg {
      transform: rotate(90deg);
    }
  }

  .forward {
    /* stylelint-disable */
    @extend .back;
    margin-left: 0.5rem;
    > svg {
      transform: rotate(-90deg);
    }
  }
  &.disabled {
    @include mixins.disabledText;
  }
  &.ocean {
    svg > path {
      fill: theme.$ocean;
    }
  }
  &.snow {
    svg > path {
      fill: theme.$snow;
    }
  }
}
