@use '../theme.module.scss';

.container {
  &.active {
    margin: 10rem 2rem;
    padding: 2rem;
    box-shadow: theme.$box-shadow;
    z-index: 200;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    max-width: fit-content;
    background-color: theme.$snow;

    @media (min-width: theme.$breakpoint-medium) {
      margin: 100px auto;
    }
  }

  &.inactive {
    display: none;
  }
}

.overlay {
  background-color: theme.$ocean;
  opacity: 0.8;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}

.headerWrapper {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;

  @media (min-width: theme.$breakpoint-small) {
    margin: 2rem;
  }
}

.content > * {
  margin: auto;

  @media (min-width: theme.$breakpoint-small) {
    margin: 2rem;
  }
}
