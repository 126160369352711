@use '../theme.module.scss';

.container {
  height: 78px;
  width: 100%;
  background-color: theme.$snow;
  margin: 0;

  .contentWrapper {
    display: flex;
    width: 100%;
    height: 100%;
    min-height: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    z-index: 2;
    position: relative;
    background-color: inherit;

    .langSelectorMobile {
      margin-right: 20px;
    }
  }

  .logoLink {
    flex: 1;
  }

  .mobileMenuIcon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 26px;
    width: 26px;

    .dot {
      display: block;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: theme.$midnight;
      transition: all 0.4s;
    }

    .fourthDot {
      display: none;
    }

    &.open {
      .dot {
        height: 4px;
        width: 30px;
        border-radius: 3px;
        margin-top: -2px;
        margin-bottom: 0;
        background-color: theme.$neon-grass;
      }

      .firstDot {
        position: absolute;
        right: 1px;
        transform-origin: bottom right;
        transform: rotate(-45deg);
      }

      .secondDot,
      .thirdDot {
        position: absolute;
        left: 2px;
        transform-origin: bottom left;
        transform: rotate(45deg);
      }
    }
  }

  .content {
    background-color: inherit;
    z-index: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    bottom: 0;
    overflow: hidden;

    .langSelectorDesktop {
      display: none;
    }

    .authButton {
      margin-top: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &.closeMenu {
      height: 0;
      transition: height 0.25s ease-out;

      > * {
        height: 0;
        transition: border-style 0.05 ease-out;
        transition-delay: 0.2s;
        border-style: hidden;
      }
    }

    &.openMenu {
      height: 300px;
      transition: height 0.25s ease-in;
      box-shadow: theme.$box-shadow;
      padding-bottom: 20px;

      > * {
        height: auto;
        transition: border 0.15s ease-in;
        transition-delay: 0.1s;
      }
    }
  }

  .logo {
    width: auto;
    height: 35px;
    margin: 0;
  }

  @media (min-width: theme.$breakpoint-large) {
    display: flex;

    .contentWrapper {
      align-items: center;
      width: max-content;

      .mobileMenuIcon {
        display: none;
      }

      .langSelectorMobile {
        display: none;
      }
    }

    .content {
      &.openMenu,
      &.closeMenu {
        display: flex;
        flex-direction: row;
        height: 100%;
        justify-content: space-between;
        overflow: visible;

        > * {
          display: flex;
          height: 100%;
          justify-content: center;
          align-items: center;
        }

        & .items {
          flex-grow: 1;
          justify-content: flex-start;
        }

        .authButton {
          margin-right: 20px;
        }

        .langSelectorDesktop {
          margin-right: 20px;
        }
      }
    }

    .logo {
      width: auto;
      height: 55px;
      padding: 10px;
      margin: 0 30px;
    }
  }
}
