@use '../../sharedComponents/theme.module.scss';

@mixin selected-style {
  font-weight: 600;
  border-bottom-color: #00ffe0;
  border-bottom-style: solid;
  border-bottom-width: 5px;
}

.container {
  position: relative;
  cursor: pointer;
}

.langList {
  position: absolute;
  top: 175%;
  right: 0;
}

.langListItem {
  padding: 10px;
  border-bottom: solid 1px theme.$midnight-10;
  height: 60px;
  width: 130px;
  background-color: theme.$snow;
  display: flex;
  align-items: center;

  p {
    border-bottom: solid 5px transparent;
  }
  &.selected,
  &:hover {
    p {
      @include selected-style;
    }
  }
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  > :first-child {
    height: 30px;
    width: 30px;
    svg {
      height: 20px;
      width: 20px;
    }
  }
}

.labelWrapper {
  display: none;
}
