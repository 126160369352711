.container {
  padding-top: 20px;
  padding-bottom: 50px;
}

.contentWrapper {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.hiddenRight,
.hiddenLeft {
  visibility: hidden;
  transition:
    width 700ms,
    visibility 100ms;
  width: 0%;
  // overflow: hidden;
}

.visibleRight,
.visibleLeft {
  visibility: visible;
  transition:
    width 700ms,
    visibility 500ms;
  width: 100%;
  // overflow: hidden;
}

.noProvidersAvailableText {
  margin: 10px 0px;
}
