@use '../../sharedComponents/theme.module.scss';

.container {
  width: 100%;
  padding: 6rem 3rem;
  background-color: theme.$ocean;
  color: theme.$snow;
}

.legalLinks {
  position: relative;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-flow: row wrap;
  align-content: center;
  gap: 1rem;
}

.anchors {
  &:hover,
  &:active {
    text-decoration: underline;
    text-underline-offset: 4px;
  }
}
