@use '../../theme.module.scss';
@use '../../theme/colors.module.scss';

.container {
  border-bottom: solid 1px colors.$midnight-10;
  height: 60px;
  width: 335px;
  background-color: colors.$snow;
  display: flex;
  align-items: center;
  padding: 20px;

  input[type='checkbox'] {
    border: solid 1px colors.$midnight;
    height: 18px;
    min-height: 18px;
    width: 18px;
    min-width: 18px;
    margin-right: 10px;
    position: relative;
    cursor: pointer;
  }

  input[type='checkbox']:checked {
    background-color: colors.$midnight;
    outline: none;
  }

  &.checked {
    background-color: colors.$neon-grass-5;
  }

  label {
    display: flex;
    align-items: center;
  }

  input[type='checkbox']:checked::after {
    content: '';
    position: absolute;
    height: 60%;
    min-height: 60%;
    width: 30%;
    min-width: 30%;
    margin: auto;
    inset: 0;
    transform: translateY(-22%) rotate(45deg);
    border-right: 2px solid colors.$neon-grass;
    border-bottom: 2px solid colors.$neon-grass;
  }
}
