.container {
  margin: 10px auto;
}

.titleElement {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 103px;
}

.contentElement {
  margin-bottom: 20px;
}

.upcomingMessage {
  margin-top: 20px;
}

.titleElement,
.contentElement {
  padding: 0 5px;
}
.payoutContent {
  margin-top: 30px;
}
.modalWrapper {
  > h5 {
    margin-top: 30px;
    margin-bottom: 10px;
  }
}
