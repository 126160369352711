@use '../theme.module.scss';
@use '../styles/mixins.scss';

$icon-dimension: 40px;
$icon-padding: 10px;

@mixin iconColorVariant($primary, $secondary) {
  :enabled {
    .iconWrapper {
      background-color: $primary;

      svg {
        path,
        g > * {
          fill: $secondary;
        }
      }
    }
  }
}

@mixin colorVariant($primary, $secondary) {
  background-color: $primary;

  @include iconColorVariant($secondary, $primary);

  &.expanded {
    @include iconColorVariant(theme.$neon-grass, theme.$ocean);
  }
}

.container {
  box-shadow: theme.$box-shadow;
  height: auto;
  overflow: auto;

  &.selected {
    border-left: 4px solid theme.$neon-grass;
  }

  .btn {
    @include mixins.disabled;

    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    padding: 15px;
    align-items: center;
    justify-content: space-between;

    &:disabled {
      @include mixins.disabledElement;

      .iconWrapper {
        @include mixins.disabledSVG;
      }
    }
  }

  .titleWrapper {
    width: auto;
    max-width: calc(100% - ($icon-dimension + $icon-padding * 2));
  }

  .iconWrapper {
    display: flex;
    height: $icon-dimension;
    width: $icon-dimension;
    min-height: $icon-dimension;
    min-width: $icon-dimension;
    border-radius: 40px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: $icon-padding;

    svg {
      transition: all 0.5s;
      display: block;
      height: 15px;
      transform: rotate(0deg);
    }
  }

  .content {
    height: auto;
    transition: all 0.5s ease;
    padding: 0 15px;
    max-height: 0;
    overflow: hidden;
  }

  &.snow {
    @include colorVariant(theme.$snow, theme.$ocean);
  }

  &.ocean {
    @include colorVariant(theme.$ocean, theme.$snow);
  }

  &.expanded {
    .iconWrapper {
      svg {
        transform: rotate(45deg);
      }
    }

    .content {
      max-height: 500px;
      padding-bottom: 15px;
    }
  }
}
