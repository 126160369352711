@use 'sass:map';
@use '../theme.module.scss';

//FOR ELEMENTS THAT HAVE DISABLED ATTRIBUTE EX.: INPUT, TEXTAREA, BUTTON
@mixin disabled {
  &:disabled {
    color: theme.$midnight-40;
    background: theme.$midnight-10;
    cursor: unset;
    pointer-events: none;
  }
}

//FOR ELEMENTS THAT DO NOT HAVE DISABLED ATTRIBUTE EX.: DIV, TEXT, CONTAINER
@mixin disabledElement {
  color: theme.$midnight-40;
  background: theme.$midnight-10;
  pointer-events: none;
  * {
    color: theme.$midnight-40;
  }
}

//FOR SVG INSIDE OF DISABLED COMPONENT
@mixin disabledSVG {
  fill: theme.$midnight-40;

  * {
    fill: theme.$midnight-40;
  }
}

//FOR ELEMENTS THAT RENDER AS JUST TEXT OR TEXT AND SVG
@mixin disabledText {
  color: theme.$midnight-40;
  fill: theme.$midnight-40;
  cursor: unset;
  pointer-events: none;
  * {
    color: theme.$midnight-40;
  }
}
