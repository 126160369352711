@use '../theme.module.scss';
@use '../styles/mixins.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: left;
  width: max-content;
  height: 4rem;
}

.btn {
  border: none;
  padding: 2px;
  text-align: left;

  &:disabled,
  &:disabled > p.title {
    @include mixins.disabledText;
  }
}

.error {
  margin-top: 2px;
  padding: 0 2px;
}
