@use '../theme.module.scss';

$num-columns: 4;

.container {
  max-height: max-content;
  min-height: 100vh;
  min-width: theme.$breakpoint-xs;
  margin: 0 auto;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: repeat($num-columns, 1fr);

  .content {
    display: grid;
    height: max-content;
    grid-column: 1 / span $num-columns;
  }

  .navigation {
    z-index: 1000;
    box-shadow: theme.$box-shadow;
  }

  .navigation,
  .footer {
    height: max-content;
    display: flex;
    place-content: center center;
    grid-column: 1 / span $num-columns;
  }
}
