.container {
  overflow: hidden;
  width: 100%;

  th {
    padding-bottom: 10px;
  }

  th,
  td {
    vertical-align: bottom;
  }

  table {
    width: 100%;
    border-spacing: 25px 10px;
    border-collapse: separate;
    margin: -10px -25px;
    table-layout: fixed;
  }
}
