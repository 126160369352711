.greeting,
.footer {
  padding-top: 4rem;
}

.greeting {
  padding-bottom: 4em;
}

.footer {
  padding-bottom: 12rem;
}

.footer a {
  text-decoration: underline;
}
.greeting,
.footer {
  :not(:last-child) {
    margin-bottom: 1rem;
  }
}
