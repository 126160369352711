@use '../theme.module.scss';
@use 'sass:math';

.button {
  position: relative;
  text-decoration: underline;
  text-decoration-color: theme.$neon-grass;
  text-decoration-thickness: 2.5px;
  font-size: 1.4rem;
  line-height: inherit;
  font-weight: inherit;
  padding: 0;
  color: inherit;

  @media (min-width: theme.$breakpoint-small) {
    font-size: 1.6rem;
  }

  a {
    text-decoration: underline;
    text-decoration-color: inherit;
    text-decoration-thickness: inherit;
  }

  &:disabled {
    cursor: unset;
    pointer-events: none;
    opacity: 0.6;
  }

  &:focus {
    outline: 1px solid theme.$neon-grass;
  }

  &:hover:enabled {
    @include theme.getThemedProperty(color, neon-grass);
  }

  &:active:enabled {
    @include theme.getThemedProperty(color, neon-grass);
  }

  &.caret {
    margin-top: 10px;
    display: block;
  }

  &.inline {
    display: inline-block;
  }
}
