@use '../../sharedComponents/theme.module.scss';

$geo-sun-size: 250px;
.header {
  display: flex;
  align-items: center;
  padding: 40px 0px;
  height: auto;
  min-height: 160px;

  * {
    position: relative;
    z-index: 0;
  }
}

.geoSunContainer {
  height: $geo-sun-size;
  width: $geo-sun-size;
  position: absolute;
  right: 0px;
  top: 0px;
  overflow: hidden;

  .geoSunLayer {
    position: absolute;
    right: -60px;
    height: $geo-sun-size;
    width: $geo-sun-size;

    > svg {
      position: relative;
      z-index: 0;
    }
  }
}

.formMessage {
  margin-top: 60px;
}

.form {
  margin: auto;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    margin-top: 40px;
  }
}

$left-align-margin: 40px;

.termsAndPoliciesText {
  margin: 60px $left-align-margin 20px;
  text-align: justify;
}

.links {
  margin: 20px auto 20px $left-align-margin;
}

@media (min-width: theme.$breakpoint-small) {
  .header {
    justify-content: center;
  }
}
