// Main
$sand: #f7f0e9;
$ocean: #0c4261;
$midnight: #0b1419;

// Accent
$snow: #fff;
$neon-grass: #00ffe0;
$neon-sun: #ffe600;
$lava: #ff002d;

// Status
$status-green: #019e8b;
$status-yellow: #a79600;
$status-red: #d40025;
$sand-80: #f9f3ed;
$sand-60: #faf6f2;
$sand-40: #fcf9f6;
$sand-20: #fdfcfb;
$ocean-80: #3d6881;
$ocean-60: #6d8ea0;
$ocean-40: #9eb3c0;
$ocean-20: #ced9df;
$ocean-10: #e7ecef;
$ocean-5: #f3f6f7;
$midnight-80: #3c4347;
$midnight-60: #6d7275;
$midnight-40: #9da1a3;
$midnight-20: #ced0d1;
$midnight-10: #e7e8e8;
$midnight-5: #f3f3f4;
$midnight-3: #f8f8f8;

/* used for the date picker */
$neon-grass-40: rgb(0 255 224 / 40%);
$neon-grass-20: rgb(0 255 224 / 20%);

/* TODO: #827 replace with correct color once revised/approved in Figma */
$neon-grass-5: rgb(0 255 224 / 5%);

:export {
  sand: $sand;
  ocean: $ocean;
  midnight: $midnight;
  snow: $snow;
  neon-grass: $neon-grass;
  neon-sun: $neon-sun;
  lava: $lava;
  status-green: $status-green;
  status-yellow: $status-yellow;
  status-red: $status-red;
  sand-80: $sand-80;
  sand-60: $sand-60;
  sand-40: $sand-40;
  sand-20: $sand-20;
  ocean-80: $ocean-80;
  ocean-60: $ocean-60;
  ocean-40: $ocean-40;
  ocean-20: $ocean-20;
  ocean-10: $ocean-10;
  ocean-5: $ocean-5;
  midnight-80: $midnight-80;
  midnight-60: $midnight-60;
  midnight-40: $midnight-40;
  midnight-20: $midnight-20;
  midnight-10: $midnight-10;
  midnight-5: $midnight-5;
  midnight-3: $midnight-3;
  neon-grass-40: $neon-grass-40;
  neon-grass-20: $neon-grass-20;
  neon-grass-5: $neon-grass-5;
}
