@use '../../sharedComponents/theme.module.scss';

.btn {
  width: 100%;
  height: 6.5rem;
  padding: 0.5rem 2rem;
  margin: 1rem 0;
  box-shadow: theme.$box-shadow;
  > svg {
    height: 1.7rem;
  }
  &:active {
    background-color: theme.$neon-grass;
  }
  &:disabled {
    h5 {
      color: theme.$midnight-40;
    }
    filter: grayscale(80%);
    background: theme.$midnight-10;
    cursor: unset;
    pointer-events: none;
  }
}

.buttonLabel {
  width: 100%;
  height: auto;
  align-items: flex-end;
  justify-content: space-between;
  display: flex;
}

.ocean {
  background-color: theme.$ocean;
}

.snow {
  background-color: theme.$snow;
}

.displayName {
  text-align: right;
  width: auto;
  max-width: 40%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
