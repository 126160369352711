@use '../../sharedComponents/theme.module.scss';
@use 'sass:math';

@mixin disabled {
  &:disabled {
    color: theme.$midnight-40;
    background: theme.$midnight-10;
    cursor: unset;
    pointer-events: none;
  }
}

.container {
  display: inline-block;
  position: relative;
}

.button {
  @include disabled;
  display: inline-block;
  position: relative;
  text-decoration: none;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  letter-spacing: 1px;
  height: 50px;
  box-shadow: theme.$box-shadow;
  width: 335px;
  margin: auto;

  .loader {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  // TODO: #1226 replace theme.$color with theme.getThemedProperty
  .loaderDot {
    background: theme.$neon-grass;
  }

  &.ocean {
    background: theme.$ocean;
    color: theme.$snow;

    .loader {
      background: theme.$ocean;
    }
  }

  &.snow {
    background: theme.$snow;
    color: theme.$ocean;

    .loader {
      background: theme.$snow;
    }
  }

  &.ocean,
  &.snow {
    // @include mixins.disabled;
    @media (hover: hover) {
      &:hover {
        background-color: theme.$neon-grass;
        color: theme.$ocean;
      }
    }
    &:active:enabled {
      background: theme.$neon-grass-40;
      @media (hover: none) {
        background-color: theme.$neon-grass;
        color: theme.$ocean;
      }
    }
  }
}

.errorWrapper {
  height: 30px;
  display: flex;
  flex-direction: column;

  p {
    margin-top: 10px;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.25);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.25);
  }
}

.loaderDot {
  border-radius: 50%;
  margin: 0.2rem;
  height: 8px;
  width: 8px;

  @for $i from 1 through 3 {
    &:nth-child(#{$i}) {
      animation: pulse 1s #{#{$i * 0.15}s} ease-in-out infinite;
    }
  }
}
