@use '../../sharedComponents/theme.module.scss';

.container {
  width: 100%;
  display: grid;
  grid-template-columns: 30% 70%;
  background-color: theme.$ocean;

  padding: 2rem;
  box-shadow: theme.$box-shadow;
  margin-bottom: 1.5rem;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
  .header {
    display: flex;
    grid-column: 1 / 2;
    height: 100%;
    > p {
      color: theme.$snow;
      // this font weight change is an exception to the rule
      // was requested by Kayla
      font-weight: 500;
    }
    & :first-child {
      & * > path {
        fill: theme.$snow;
      }
    }
  }
  .detailsPositionWrapper {
    height: 100%;
    grid-column: 2/3;
    margin-left: 2rem;

    @media (min-width: theme.$breakpoint-medium) {
    }
    > * {
      color: theme.$snow;
    }
  }

  @media (min-width: theme.$breakpoint-small) {
    padding: 4rem;
  }
}
