@use '../theme.module.scss';
@use '../styles/mixins.scss';

.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: auto;
  color: theme.$midnight;

  svg {
    fill: theme.$midnight;
    height: 16px;
    width: auto;
    margin-right: 5px;
  }

  &:active {
    * {
      color: theme.$neon-grass;
    }

    svg {
      fill: theme.$neon-grass;
    }
  }

  &:disabled {
    cursor: unset;
    pointer-events: none;

    * {
      @include mixins.disabledText;
    }

    svg {
      @include mixins.disabledSVG;
    }
  }

  @media (min-width: theme.$breakpoint-small) {
    svg {
      height: 20px;
      width: auto;
    }
  }
}
