@use '../theme.module.scss';
@use '../styles/mixins.scss';
@use '../theme/colors.module.scss';

$icon-height: 30px;

.container {
  width: fit-content;

  .contentWrapperButton,
  .contentWrapperDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;

    svg {
      fill: colors.$midnight;
      height: $icon-height;
      width: auto;
    }

    &:disabled {
      @include mixins.disabledText;

      * {
        @include mixins.disabledText;
      }
    }
  }

  .contentWrapperButton {
    * {
      text-decoration: underline;
      text-decoration-thickness: 0.07em;
    }
  }
}

.iconWrapper {
  height: $icon-height;
  width: $icon-height;
  margin-right: 12px;
}
