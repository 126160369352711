@use '../../sharedComponents/theme.module.scss';

.modalWrapper {
  > h5 {
    margin-top: 30px;
    margin-bottom: 10px;
  }

  max-width: 50rem;
  padding-top: 1rem;
  padding-bottom: 4rem;
}
