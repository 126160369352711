@use '../../sharedComponents/theme.module.scss';

.backBTNWrapper {
  display: flex;
  align-items: center;
  padding: 2rem 1rem;

  > svg {
    height: 1.5rem;
    width: 1.5rem;
    margin-right: 0.5rem;
    transform: rotate(90deg);

    > path {
      fill: theme.$ocean;
    }
  }
}

.heading {
  padding: 0.5rem;

  > h4 {
    margin: 2rem 0;
  }
}

.spacingWrapper {
  margin-top: 5rem;
}
