@use '../theme.module.scss';

/* stylelint-disable */

@each $name in theme.$typography-element-names {
  .#{$name} {
    @extend %#{$name};
  }
}

@mixin colorVariant($primary, $aTag) {
  color: $primary;

  a {
    color: $aTag;
  }
}

.ocean {
  @include colorVariant(theme.$ocean, theme.$neon-grass);
}

.snow {
  @include colorVariant(theme.$snow, theme.$neon-grass);
}

.midnight {
  @include colorVariant(theme.$midnight, theme.$ocean);
}

.midnight-40 {
  @include colorVariant(theme.$midnight-40, theme.$ocean);
}

.midnight-60 {
  @include colorVariant(theme.$midnight-60, theme.$ocean);
}

.neon-grass {
  @include colorVariant(theme.$neon-grass, theme.$ocean);
}

.status-green {
  @include colorVariant(theme.$status-green, theme.$neon-grass);
}

.status-yellow {
  @include colorVariant(theme.$status-yellow, theme.$neon-grass);
}

.status-red {
  @include colorVariant(theme.$status-red, theme.$neon-grass);
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}
