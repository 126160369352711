@use '../theme.module.scss';

.container {
  display: flex;
  flex-direction: column;
  width: min-content;
}

.checkboxWrapper {
  display: flex;
  flex-direction: column;
  box-shadow: theme.$box-shadow;
  margin-top: 10px;
}

.labelWrapper {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
